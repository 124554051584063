<template>
    <div class="success">
        <div class="center">
            <img src="../img/yes.png" alt="">
            <p class="title">
                预约成功
            </p>
            <p class="content">
                预约课程：微服务高并发实战训练营
            </p>
            <p class="msg">
                请保持手机畅通，平台会通过电话联系您
            </p>
            <div class="back" @click="$router.push('/')">
                返回
            </div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.back{
    width: 3.54rem;
    height: .80rem;
    border-radius: .40rem;
    border: 1px solid #1D83F0;
    font-size: .26rem;
    font-family: PingFang-SC-Medium, PingFang-SC;
    font-weight: 500;
    color: #1D84F0;
    text-align: center;
    line-height: .8rem;
    margin: 0.64rem auto 0 auto;
}
.msg{
    font-size: .24rem;
    font-family: PingFang-SC-Medium, PingFang-SC;
    font-weight: 500;
    color: #999999;
    margin-top: 2.42rem;
    text-align: center;
}
.title{
    font-size: 0.32rem;
    font-family: PingFang-SC-Bold, PingFang-SC;
    font-weight: bold;
    color: #333333;
    margin-top: 1.41rem;
    text-align: center;
}
.content{
    font-size: .28rem;
    font-family: PingFang-SC-Medium, PingFang-SC;
    font-weight: 500;
    color: #333333;
    margin-top: 0.37rem;
    text-align: center;
}
.success{
    width: 7.5rem;
    height: 10rem;
    background: url('../img/success.png') no-repeat;
    background-size: 100%;
    overflow: hidden;
    padding-bottom: 3rem;
    position: relative;
    display: block;
   
}
.center{
     overflow: hidden;
    width: 6.9rem;
    height: 9.02rem;
    background: #FFFFFF;
    box-shadow: 0rem 0.04rem 0.26rem 0rem rgba(55, 89, 120, 0.12);
    border-radius: .15rem;
    margin: 1.84rem auto;
}
.center img{
    width: 1.99rem;
    height: 1.99rem;
    position: absolute;
    left: 50%;
    transform: translate(-50%,-50%);
    /* transform: translateY(-50%); */
}
</style>